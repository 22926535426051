.header-video {
  .video-image {
    background-position: center center;
    background-size: cover;
    position: absolute;
    min-height: 100vh;
    max-height: 999px;
    width: 100%;
  }
}
